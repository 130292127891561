<template>
  <div class="md-manage-guan-kan-ji-lu">
    <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="观看历史" name="first">

      <ul class="topic-list" v-if="courseList.length">
      <li class="topic-item" v-for="(item, index) in courseList" :key="index" @click="handleItem(item)">
        <div class="t-img">
          <span class="tag">视频</span>
          <img :src="`/video-dev${item.cover}`" alt="">
        </div>
        <div class="t-right">
          <div class="t-tit-box">
            <p class="t-tit">{{item.title}}</p>
            <p class="t-type">
              <span>观看时间：{{item.viewTime}}</span>
            </p>
          </div>
        </div>
      </li>
    </ul>
    <p v-else class="no-data">暂无数据</p>

    </el-tab-pane>
    <!-- <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane> -->
  
  </el-tabs>
  
    <!-- 分页 -->
    <div class="pagination" v-if="courseList.length">
      <el-pagination @current-change="handleCurrentChange" :current-page="+params.page" :page-size="params.pageSize" background layout="total, prev, pager, next, jumper" :total="total" bg>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { selectUserViewList } from '@/axios/user'
export default {
  name: 'MdManageGuanKanJiLuComponent',
  data () {
    return {
      params: {
        pageNum: 1,
        pageSize: 5,
        searchValue:''
      },
      courseList: [],
      activeName:'first',
      total: 0
    }
  },
  created () {
    this.selectUserViewList()
  },
  methods: {
    async selectUserViewList () {
      const res = await selectUserViewList(this.params)
      if (res.code === 200) {
        this.courseList = res.rows
        this.total = res.total
      }
    },
    handleCurrentChange (val) {
      this.params.pageNum = val
      this.selectUserViewList()
    },
    handleItem (item) {
      this.$router.push({
        path: '/courseDetail',
        query: {
          rid: item.videoId

        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.md-manage-guan-kan-ji-lu {
  width: 100%;
  background: #fff;
padding: 20px;
  border-radius:12px ;
}
.topic-list {
  width: 100%;
  // padding: 20px 30px;
  .topic-item {
    padding: 20px 0;
    border-bottom: 1px solid rgb(230,230,230);
    display: flex;
    align-items: center;
    cursor: pointer;
    &:first-child {
      padding-top: 0;
    }
    .t-img {
      width: 126px;
      height: 84px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 20px;
      flex-shrink: 0;
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 0 4px 0 4px;
        background: rgba(0,0,0,.2);
        color: rgba(255,255,255,.8);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .t-right {
      flex: 1;
      height: 100px;
      display: flex;
      align-items: center;
      .t-tit-box {
        flex: 1;
        .t-tit {
          font-size: 16px;
          margin-bottom: 20px;
        }
        .t-type {
          span {
            color: #888;
            font-size: 12px;
            margin-right: 30px;
          }
        }
      }
      .q-btn {
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }
}

::v-deep {
    .is-active {
      color: #0FB09B !important;
    }

    .el-tabs__active-bar {
      background-color: #0FB09B;
    }

    .el-tabs__item {
      font-size: 16px;
      color: #333333;
    }

    .el-tabs__item:hover {
      color: #0FB09B !important;
    }
    .el-tabs__header{
      // padding-left: 30px;
    }
  }
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}
::v-deep{
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #0FB09B;
  }

  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    border: 1px solid  #CCCCCC;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
   
  }
  .el-pagination button, .el-pagination span:not([class*=suffix]){
   height: 36px;
   line-height: 36px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #0FB09B;
  }
}
/deep/ .el-pagination .btn-next, .el-pagination .btn-prev {
  // background: transparent !important;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
}

/deep/ .el-pager li.active {
  color: @md-primary-color;;
}
/deep/ .el-pager li:hover {
  color: @md-primary-color;;
}
/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}
/deep/ .el-pagination button:hover {
  color: @md-primary-color;
}
</style>